// @ts-nocheck
import _ from "lodash";
import Vue from "vue";

export default {
  mounted() {
    if (_.isEmpty(this.$store.state.apieditor.apis)) {
      this.$store.dispatch("FETCH_ALL_APIS");
    }
  },
  computed: {
    apiConfigOptions() {
      const api = _.get(this.$store, "state.apieditor.apis", {});
      const options = _.map(api, (_api, name) => {
        return {
          label: name,
          value: name,
        };
      });
      return [
        {
          label: "",
          value: "",
        },
        ...options,
      ];
    },
    developerFormMixin(): any {
      return {
        incomingRequest: {
          default: {},
          type: "object",
          label: "Incoming requests",
          detail:
            "To send requests to keyreply system, please check the documentation for all available APIs. Example actions: reply to a livechat, send a form, end a livechat session",
          children: {
            enabled: {
              label: "Enable incoming requests from 3rd party developer",
              type: "boolean",
              default: false,
            },
            apiKeys: {
              type: "DevCenterApiKey",
              default: [],
              label: "Authorization",
              detail:
                "Use the keys provided for the 'x-api-key' header to access the API. You can generate a new key and then share it with the vendor. Please remember to handle these keys securely, as they grant access to the associated APIs",
            },
          },
        },
        outgoingRequest: {
          default: {},
          type: "object",
          label: "Outgoing requests",
          detail:
            "For each events on livechat flow, you can trigger a request to your endppoint. Check the documentation to learn more, including how to verify that request is coming from KR side. ",
          children: {
            enabled: {
              label: "Enable outgoing requests to external application",
              type: "boolean",
              default: false,
            },
            url: {
              label: "Webhook URL",
              type: "string",
              default: "",
              required: false,
            },
            apiConfig: {
              label: "Call API from API Editor",
              type: "select",
              default: "",
              detail:
                "Select an API configuration from API Editor. If both webhook url and api config are provided, url will be used",
              required: false,
              options: this.apiConfigOptions,
            },
            eventHooks: {
              type: "object",
              default: {},
              label: "Select events",
              detail:
                "There are all the events that will send a request to the endpoint you specified. Check the documentation to learn more.",
              children: {
                "bot:beforeTextHandler": {
                  label: "When visitor send text message",
                  type: "boolean",
                  default: false,
                },
                "handover:agentChangedStatus": {
                  label: "After agent changed status",
                  type: "boolean",
                  default: false,
                },
                "handover:afterUserRequestedLivechat": {
                  label: "After visitor requested livechat",
                  type: "boolean",
                  default: false,
                },
                "handover:afterAgentJoinedLivechat": {
                  label: "After agent joined a livechat",
                  type: "boolean",
                  default: false,
                },
                "handover:afterAgentAcceptedInvitation": {
                  label: "After agent invited to a livechat",
                  type: "boolean",
                  default: false,
                },
                "handover:afterLivechatSessionEnded": {
                  label: "After livechat session ended",
                  type: "boolean",
                  default: false,
                },
                "handover:afterLivechatSessionEscalated": {
                  label: "After livechat session escalated",
                  type: "boolean",
                  default: false,
                },
                "whatsapp:notification": {
                  label: "Receiving WhatsApp notification event",
                  type: "boolean",
                  default: false,
                },
              },
            },
          },
        },
        security: {
          default: {},
          type: "object",
          label: "Security",
          children: {
            visitorDataToBeExposed: {
              label: "Visitor data to be exposed",
              detail:
                "Allowed visitor data (state) to be exposed to. Separate by comma. Ex: RowKey,PartitionKey,session_id",
              type: "list",
              default: ["RowKey", "PartitionKey", "session_id", "userQueryTopic"],
              required: true,
            },
          },
        },
      };
    },
  },
};
