<template>
  <div>
    <el-dialog
      title="Export Websites"
      :visible.sync="dialogVisible"
      width="45%"
      :before-close="handleClose"
      destroy-on-close
      v-loading="isFetching"
    >
      <h3 style="display: block; margin-top: 0px; margin-bottom: 20px">
        Select websites to export
      </h3>
      <el-checkbox-group
        v-model="selectedWebsites"
        style="display: flex; gap: 10px; flex-wrap: wrap"
      >
        <div v-for="website in websites" :key="website.id" style="width: 40%">
          <el-checkbox :label="website.id">
            {{ website.title }}
          </el-checkbox>
        </div>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" :loading="isLoading" :disabled="isLoading"
          >Cancel</el-button
        >
        <el-button type="primary" @click="handleExport" :loading="isLoading" :disabled="isLoading">
          Export
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ExportDialog",
  props: ["dialogVisible", "isLoading"],
  data() {
    return {
      selectedWebsites: [],
      isFetching: false,
      websites: [],
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateExportDialogVisible", false);
    },
    handleExport() {
      const selectedWebsites = this.websites.filter((website) =>
        this.selectedWebsites.includes(website.id)
      );
      this.$emit("export", selectedWebsites);
    },
  },
  async created() {
    this.isFetching = true;
    const data = await this.$store.dispatch("GENAI_GET_WEBSITES_ABLE_TO_EXPORT");
    this.websites = data;
    this.isFetching = false;
  },
};
</script>
