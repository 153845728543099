import store from "@/store";
import { rest } from "@/store/api";
import _ from "lodash";

/**
 * Registers a service worker if it's supported by the browser.
 */
export async function registerServiceWorker(): Promise<ServiceWorkerRegistration | null> {
  if ("serviceWorker" in navigator) {
    try {
      return await navigator.serviceWorker.register("/sw.js");
    } catch (error) {
      console.error("Error registering service worker:", error);
    }
  }
  return null;
}

/**
 * Requests permission from the user to display notifications.
 */
export async function requestNotificationPermission(): Promise<NotificationPermission> {
  const permission = await Notification.requestPermission();
  if (permission !== "granted") {
    throw new Error("Notification permission not granted.");
  }
  return permission;
}

/**
 * Subscribes a user to push notifications, asking for permission if needed, and sends the subscription to the server.
 */
export async function subscribeToPush(email: string): Promise<void> {
  const registration = await navigator.serviceWorker.ready;
  let subscription = await registration.pushManager.getSubscription();

  if (!subscription) {
    const permission = Notification.permission;
    if (permission !== "granted") {
      await requestNotificationPermission();
    }

    const vapidPublicKey = getVapidPublicKey();
    if (!vapidPublicKey) {
      throw new Error("VAPID public key is not set.");
    }

    subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
    });
  }

  await rest("post", "notification_subscribe", { email, subscription });
}

/**
 * Unsubscribes a user from push notifications and sends the unsubscription to the server.
 */
export async function unsubscribeFromPush(email: string): Promise<void> {
  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.getSubscription();

  if (subscription) {
    await subscription.unsubscribe();
    await rest("post", "notification_unsubscribe", { email, subscription });
  }
}

/**
 * Retrieves the VAPID public key from the store.
 */
function getVapidPublicKey(): string | null {
  return _.get(store, "state.modules.notification.vapidPublicKey", null);
}

/**
 * Converts a base64-encoded string to a Uint8Array.
 */
function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
