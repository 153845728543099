<template>
  <div>
    <el-input
      @blur="onChange"
      :type="setting.protected ? 'password' : 'text'"
      :disabled="isDisabled"
      :required="setting.required"
      v-model="input"
      ref="input"
      autocomplete="off"
      style="max-width: 720px; margin-bottom: 1em"
    >
      <div v-if="mode !== 'form'" slot="prepend" style="min-width: 140px">
        {{ setting.label || name }}
        <Tooltip :label="setting.label || name" :content="setting.detail" />
      </div>

      <el-button
        @click="onEdit"
        v-if="isEditable"
        size="mini"
        slot="append"
        icon="el-icon-edit"
      ></el-button>

      <el-button
        @click="onCancelEdit"
        v-if="isEndEditable"
        size="mini"
        slot="append"
        icon="el-icon-circle-close"
      ></el-button>
    </el-input>
  </div>
</template>

<script>
import _ from "lodash";
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name", "mode"],
  data() {
    return {
      isEdit: false,
      input: "",
    };
  },
  methods: {
    /**
     * On edit password field
     *
     * @return {void}
     */
    onEdit() {
      this.isEdit = true;
      this.input = null;
      this.$refs["input"].focus();
    },

    /**
     * On end edit password field
     *
     * @return {void}
     */
    onCancelEdit() {
      this.isEdit = false;
      this.input = "********";
    },

    /**
     * On value changed, dont use normal v-model
     * Because password shouldnt be bind
     *
     * @return {void}
     */
    onChange() {
      this.$set(this.value, this.name, this.input);
      if (this.setting.protected) {
        this.isEdit = false;
        if (this.input) {
          this.value[this.name] = this.input;
          this.input = "********";
        }
      }
      this.$emit('blur');
    },
  },
  computed: {
    /**
     * Should show edit button
     * Only show if type is password
     *
     * @return {boolean}
     */
    isEditable() {
      return this.setting.protected && !this.isEdit && this.value[this.name];
    },

    /**
     * Should show cancel edit button
     * Only show if type is password
     * Only show if edit mode is on
     *
     * @return {boolean}
     */
    isEndEditable() {
      return this.setting.protected && this.isEdit && this.value[this.name];
    },

    /**
     * Should disabled input
     *
     * @return {boolean}
     */
    isDisabled() {
      return (
        this.setting.disabled ||
        (this.setting.protected && !_.isEmpty(this.value[this.name]) && !this.isEdit)
      );
    },
  },
  mounted() {
    const isBlank = this.value[this.name] == null;
    const isProtectedField = this.setting.protected;

    if (isBlank) {
      this.input = _.get(this.setting, "default");
      this.$set(this.value, this.name, _.get(this.setting, "default"));
    } else if (isProtectedField && !isBlank) {
      this.input = "********";
    } else {
      this.input = this.value[this.name];
    }
  },
};
</script>
