<template>
  <div>
    <el-dialog
      title="Export Articles"
      :visible.sync="dialogVisible"
      width="45%"
      :before-close="handleClose"
      destroy-on-close
      v-loading="isFetching"
    >
      <h3 style="display: block; margin-top: 0px; margin-bottom: 20px">
        Select articles to export
      </h3>
      <el-checkbox-group
        v-model="selectedArticles"
        style="display: flex; gap: 10px; flex-wrap: wrap"
      >
        <div v-for="article in articles" :key="article.id" style="width: 40%">
          <el-checkbox :label="article.id">
            {{ article.title }}
          </el-checkbox>
        </div>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" :loading="isLoading" :disabled="isLoading"
          >Cancel</el-button
        >
        <el-button type="primary" @click="handleExport" :loading="isLoading" :disabled="isLoading">
          Export
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ExportDialog",
  props: ["dialogVisible", "isLoading"],
  data() {
    return {
      selectedArticles: [],
      articles: [],
      isFetching: false,
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateExportDialogVisible", false);
    },
    handleExport() {
      const selectedArticles = this.articles.filter((article) =>
        this.selectedArticles.includes(article.id)
      );
      this.$emit("export", selectedArticles);
    },
  },
  async created() {
    this.isFetching = true;
    const data = await this.$store.dispatch("GENAI_GET_ARTICLES_ABLE_TO_EXPORT");
    this.articles = data;
    this.isFetching = false;
  },
};
</script>
