<template>
  <div>
    <el-dialog
      title="Export Documents"
      :visible.sync="dialogVisible"
      width="55%"
      :before-close="handleClose"
      destroy-on-close
      v-loading="isFetching"
    >
      <div>
        <h3 style="display: block; margin-top: 0px; margin-bottom: 20px">
          Select documents to export
        </h3>
        <!-- Search -->
        <el-input
          v-model="search"
          placeholder="Search documents"
          style="margin-bottom: 20px"
        ></el-input>
        <el-checkbox-group
          v-model="selectedDocuments"
          style="display: flex; gap: 40px; flex-wrap: wrap"
        >
          <div v-for="document in filterDocuments" :key="document.document_id" style="width: 40%">
            <el-checkbox :label="document.document_id">
              <span style="white-space: wrap">{{ document.document_name }}</span>
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" :loading="isLoading" :disabled="isLoading"
          >Cancel</el-button
        >
        <el-button type="primary" @click="handleExport" :loading="isLoading" :disabled="isLoading">
          Export
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "ExportDialog",
  props: ["isLoading", "dialogVisible"],
  data() {
    return {
      selectedDocuments: [],
      documents: [],
      search: "",
      isFetching: false,
    };
  },
  async created() {
    this.isFetching = true;
    const data = await this.$store.dispatch("GENAI_GET_DOCUMENTS_ABLE_TO_EXPORT");
    this.documents = data;
    this.isFetching = false;
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
    handleExport() {
      const selectedDocuments = this.documents.filter((document) =>
        this.selectedDocuments.includes(document.document_id)
      );
      this.$emit("export", selectedDocuments);
    },
    updateExportDialogVisible(value) {
      this.$emit("updateDialogVisible", value);
    },
  },
  computed: {
    filterDocuments() {
      return this.documents.filter((document) =>
        document.document_name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
};
</script>
<style></style>
