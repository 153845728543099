<template>
  <div>
    <div id="persona-header">
      <div style="width: 100%; margin-right: 10px; text-align: right">
        <!-- Import/Export buttons -->
        <el-button type="primary" size="large" icon="el-icon-download" @click="openExportDialog"
          >Export</el-button
        >
        <el-button type="primary" size="large" icon="el-icon-upload" @click="openImportDialog"
          >Import</el-button
        >
      </div>
    </div>
    <div id="persona-body">
      <div class="persona-body-left">
        <div style="margin: 5px">
          <el-popover v-model="showNewPersonaPopover" placement="left" width="344">
            <el-input
              v-model="newPersonaName"
              autofocus
              @keyup.enter.native="create"
              @keyup.esc.native="showNewPersonaPopover = false"
            >
              <template slot="prepend">Persona name</template>
              <el-button slot="append" icon="el-icon-check" @click="create" />
            </el-input>
            <el-tooltip slot="reference" class="item" placement="bottom" content="Add new persona">
              <el-button type="primary">Add new persona</el-button>
            </el-tooltip>
          </el-popover>
        </div>
        <ul class="persona-list">
          <li
            class="persona-item"
            :class="{ selected: selectedPersonaIndex === index }"
            :key="index"
            v-for="(persona, index) in sortedPersonaList"
            @click="setSelectPersona(index)"
          >
            <el-input
              v-if="selectedPersonaIndex === index && !isDefaultPersona"
              size="small"
              type="text"
              placeholder="Please input"
              v-model="selectedPersona.name"
              style="width: 95%; margin-right: 5px"
            />
            <div
              v-else
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
              "
            >
              <span>{{ persona.name }} </span>
              <!-- System Persona Tag -->
              <el-tag v-if="persona.systemPersona" type="info" size="mini" style="margin-left: 15px"
                >System</el-tag
              >
            </div>

            <!-- tooltip warning that have unsave personas -->
            <el-tooltip
              class="item"
              effect="dark"
              content="Unsaved changes"
              placement="right"
              v-if="unSavedChanges.includes(persona.id)"
            >
              <i class="el-icon-warning" style="color: #e6a23c"></i>
            </el-tooltip>
          </li>
        </ul>
      </div>
      <div class="persona-body-right">
        <div class="responsive-wrapper">
          <div
            class="meta-prompt persona-body-item"
            style="
              display: flex;
              align-items: end;
              justify-content: space-between;
              margin-bottom: 30px;
            "
          >
            <div class="persona-body-item-wrapper">
              <div style="width: 100%; text-align: end">
                <el-button class="save-button" type="primary" :loading="saving" @click="saveChanges"
                  >Save</el-button
                >

                <el-button
                  class="save-button"
                  type="danger"
                  :loading="saving"
                  @click="handleDeletePersona"
                  v-if="!isDefaultPersona && !selectedPersona.systemPersona"
                  >Delete</el-button
                >
              </div>
            </div>
          </div>

          <el-collapse v-model="activeNames">
            <!-- Prompt Section -->
            <el-collapse-item name="1">
              <template slot="title">
                <div class="meta-prompt-title persona-body-title">
                  <span>Prompt</span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="The prompt that defines the chat bot's persona."
                    placement="right"
                  >
                    <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                  </el-tooltip>
                </div>
              </template>
              <div style="padding: 1px" v-if="selectedPersona.name">
                <PromptGeneration :key="selectedPersona.name" :selectedPersona="selectedPersona" />
              </div>
            </el-collapse-item>

            <el-collapse-item name="2">
              <template slot="title">
                <div class="meta-prompt-title persona-body-title">
                  <span> Connect to Knowledge Base </span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Select relevant tags from the knowledge base to define the specific scope and expertise of your bot."
                    placement="right"
                  >
                    <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                  </el-tooltip>
                </div>
              </template>
              <div class="persona-body-item-wrapper">
                <div style="width: 100%">
                  <div class="persona-table-wrapper">
                    <el-table
                      :data="document_tags"
                      border
                      v-if="document_tags.length > 0"
                      :key="selectedPersonaIndex"
                      style="width: 100%"
                    >
                      <el-table-column label="Enable" align="center">
                        <template slot-scope="scope">
                          <el-checkbox
                            @change="handleTagChange(scope.row.name, $event)"
                            :checked="isPersonaTagsContain(scope.row.name)"
                          ></el-checkbox>
                        </template>
                      </el-table-column>
                      <el-table-column label="Category" prop="name" align="center">
                      </el-table-column>
                      <el-table-column label="Tags">
                        <template slot-scope="scope">
                          <div style="display: inline-block">
                            <el-select
                              placeholder="Select tags..."
                              multiple=""
                              v-model="selectedTags[scope.row.name]"
                              :disabled="!isPersonaTagsContain(scope.row.name)"
                            >
                              <el-option
                                v-for="(tag, index) in scope.row.tags"
                                :key="index"
                                :label="tag"
                                :value="tag"
                              ></el-option>
                            </el-select>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </el-collapse-item>

            <!-- Actions Section -->
            <el-collapse-item title="Actions" name="3">
              <template slot="title">
                <div class="meta-prompt-title persona-body-title">
                  <span> Actions </span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Actions to be performed before and after the bot's response."
                    placement="right"
                  >
                    <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                  </el-tooltip>
                </div>
              </template>
              <div class="persona-body-item">
                <el-card>
                  <div style="display: flex; gap: 20px">
                    <div style="width: 50%">
                      <span>Before action</span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Action to be performed before the bot's response."
                        placement="right"
                      >
                        <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                      </el-tooltip>
                      <el-card style="margin: 20px auto">
                        <EventNodeSelector :value="beforeBotAction" :isGenAIModule="true" />
                      </el-card>
                    </div>

                    <div style="width: 50%">
                      <span>After action</span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Action to be performed after the bot's response."
                        placement="right"
                      >
                        <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                      </el-tooltip>
                      <el-card style="margin: 20px auto">
                        <EventNodeSelector :value="afterBotAction" :isGenAIModule="true" />
                      </el-card>
                      <span>Hide bot response</span>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Ignore the bot's response render on after action available."
                        placement="right"
                      >
                        <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                      </el-tooltip>
                      <el-switch
                        v-model="selectedPersona.ignoreChatOnAfterBot"
                        style="margin-left: 20px"
                      />
                    </div>
                  </div>
                </el-card>
              </div>
            </el-collapse-item>

            <!-- Plugins Section -->
            <el-collapse-item title="Plugins" name="4">
              <template slot="title">
                <div class="meta-prompt-title persona-body-title">
                  <span> Plugins </span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Manage and configure plugins to extend the bot's functionality. Enable, disable, or customize tools for a more dynamic and versatile experience."
                    placement="right"
                  >
                    <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                  </el-tooltip>
                </div>
              </template>
              <div class="persona-body-item">
                <el-card>
                  <div style="margin-bottom: 10px">
                    <el-button type="primary" @click="showModal(null)">Add Custom Plugin</el-button>
                  </div>
                  <el-table :data="tableFormatPlugins" border>
                    <el-table-column label="Enable" width="100px">
                      <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.enabled" />
                      </template>
                    </el-table-column>
                    <el-table-column label="Name">
                      <template slot-scope="scope">
                        <span
                          v-if="scope.row.type === 'default'"
                          style="text-transform: capitalize"
                          >{{ formatTableName(scope.row.name) }}</span
                        >

                        <span v-else>{{ scope.row.config.name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column align="center">
                      <template slot-scope="scope">
                        <el-button
                          v-if="scope.row.config"
                          type="primary"
                          size="mini"
                          @click="showModal(scope.row)"
                          >Configure</el-button
                        >

                        <!-- delete button -->
                        <el-button
                          v-if="scope.row.type === 'custom'"
                          type="danger"
                          size="mini"
                          @click="handleDeletePlugin(scope.row)"
                          >Delete</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </el-card>
              </div>
            </el-collapse-item>

            <el-collapse-item name="5" v-if="$store.state.showAdvanced">
              <template slot="title">
                <div class="meta-prompt-title persona-body-title">
                  <span> Advanced Settings </span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Adjust the bot's behavior and preferences for a more tailored experience."
                    placement="right"
                  >
                    <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                  </el-tooltip>
                </div>
              </template>
              <div class="wrap-responsive persona-body-item-wrapper" style="gap: 40px">
                <div class="meta-prompt persona-body-item" style="width: 40%">
                  <div
                    class="meta-prompt-title persona-body-title"
                    style="display: flex; align-items: center; gap: 20px"
                  >
                    <div style="width: 100%">
                      <div style="height: 100%; padding-bottom: 5px">Temperature</div>
                      <el-slider
                        v-model="selectedPersona.temperature"
                        :min="0"
                        :max="2"
                        :step="0.1"
                        show-input
                        style="width: 100%; padding-left: 10px"
                      ></el-slider>
                    </div>
                  </div>
                </div>

                <div class="meta-prompt persona-body-item" style="width: 40%">
                  <div
                    class="meta-prompt-title persona-body-title"
                    style="display: flex; align-items: center; gap: 5px"
                  >
                    <div style="width: 100%">
                      <div style="height: 100%; padding-bottom: 5px">Chat History Limit</div>
                      <el-slider
                        v-model="selectedPersona.chatHistoryLimit"
                        :min="0"
                        :max="100"
                        :step="1"
                        show-input
                        style="width: 100%; padding-left: 10px"
                      ></el-slider>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div style="display: flex">
                  <div style="width: 45%">
                    <span class="meta-prompt persona-body-item" style="font-size: 16px"
                      >Stream Responses</span
                    >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Enable to receive the AI's response as it is being generated, or disable to get the full response all at once."
                      placement="right"
                    >
                      <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                    </el-tooltip>
                    <el-switch
                      v-model="selectedPersona.streaming_response"
                      style="margin-left: 20px"
                    />
                  </div>
                  <div v-if="pii_modules.enabled">
                    <!-- PII -->
                    <span class="meta-prompt persona-body-item" style="font-size: 16px">PII</span>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Enable PII Redaction for this persona to remove sensitive personal information."
                      placement="right"
                    >
                      <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                    </el-tooltip>
                    <el-switch v-model="selectedPersona.pii" style="margin-left: 20px" />
                  </div>
                </div>

                <div style="margin-top: 40px">
                  <el-button type="primary" @click="promptEditDialogVisible = true"
                    >Edit Raw Prompt</el-button
                  >
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="flex: 1">
          <PreviewWidget :selectedPersona="selectedPersona" />
        </div>
      </div>
    </div>
    <component
      v-if="selectedModal && dialogVisible"
      :is="selectedModal"
      :dialogVisible="dialogVisible"
      :selectedPlugin="selectedPlugin"
      :currentCustomPlugins="currentCustomPlugins"
      @updateDialogVisible="updateDialogVisible"
      @updatePluginConfig="updatePluginConfig"
      @addCustomPlugin="addCustomPlugin"
    />

    <el-dialog
      title="Prompt Preview"
      :visible.sync="promptEditDialogVisible"
      width="50%"
      top="20px"
    >
      <el-input
        v-model="selectedPersona.prompt"
        type="textarea"
        :autosize="{ minRows: 10, maxRows: 20 }"
        placeholder="Generated Prompt"
        style="word-break: break-word"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="promptEditDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="promptEditDialogVisible = false">Confirm</el-button>
      </span>
    </el-dialog>

    <ExportDialog ref="exportDialog" :personas="personas" @export="exportPersonas" />
    <ImportDialog ref="importDialog" :isLoading="isLoadingImport" @import="importPersonas" />
  </div>
</template>

<script>
import {
  DEFAULT_PERSONA_PROMPT,
  DEFAULT_PERSONA_MODEL,
  PERSONA,
  DEFAULT_PERSONA_NAME,
} from "../constants";
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import JiraModal from "../Plugins/JiraModal.vue";
import CustomizeModal from "../Plugins/CustomizeModal.vue";
import SearchEngineModal from "../Plugins/SearchEngineModal.vue";
import PreviewWidget from "./PreviewWidget.vue";
import PromptGeneration from "./PromptGeneration.vue";
import ExportDialog from "./ExportDialog.vue";
import ImportDialog from "./ImportDialog.vue";
import { v4 as uuid } from "uuid";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      saving: false,
      selectedPersonaIndex: 0,
      newPersonaName: "",
      showNewPersonaPopover: false,
      selectedTags: {},
      selectedPersona: {},
      beforeBotAction: {
        event: "",
        data: {},
        side_effects: Array,
      },
      afterBotAction: {
        event: "",
        data: {},
        side_effects: Array,
      },
      selectedModel: DEFAULT_PERSONA_MODEL,
      selectedChatService: "OPENAI",
      unSavedChanges: [],
      tempPersonas: [],
      initPlugins: [
        {
          name: "search_engine",
          enabled: false,
          config: {
            name: "",
            whiteList: [],
          },
        },
        {
          name: "jira",
          enabled: false,
          config: {
            apiKey: "",
            email: "",
            url: "",
          },
        },
      ],
      tableFormatPlugins: [],
      dialogVisible: false,
      selectedModal: null,
      selectedPlugin: null,
      activeNames: ["1"],
      sortedPersonaList: [],
      promptEditDialogVisible: false,
      originSelectedPersona: {},
      allowEditRoles: ["admin", "genai-editor"],
      isLoadingImport: false,
    };
  },
  components: {
    EventNodeSelector,
    JiraModal,
    SearchEngineModal,
    PreviewWidget,
    PromptGeneration,
    ExportDialog,
    ImportDialog,
  },
  computed: {
    ...mapGetters(["getUserAuthentication"]),
    personas() {
      return this.$store.state.genai.genai_personas;
    },
    settings() {
      return this.$store.state.modules.genai;
    },
    pii_modules() {
      return this.$store.state.modules.pii;
    },
    document_tags() {
      return this.$store.state.modules.genai?.knowledge_document?.tags || [];
    },
    isDefaultPersona() {
      return this.selectedPersona.name === DEFAULT_PERSONA_NAME;
    },
    currentCustomPlugins() {
      return this.tableFormatPlugins.filter((plugin) => plugin.type === "custom");
    },
    userProfile() {
      return this.$store.state.profile;
    },
  },
  methods: {
    saveChanges({ event = "update" }) {
      this.saving = true;

      if (!this.isAbleToEditPersona(this.selectedPersona)) {
        this.$message({
          type: "error",
          message: "You don't have permission to edit this persona",
        });
        this.saving = false;
        return;
      }

      this.selectedPersona.pluginConfig = this.tableFormatPlugins.reduce((acc, plugin) => {
        if (!plugin.config) {
          acc[plugin.name] = {
            enabled: plugin.enabled,
          };
          return acc;
        }

        acc[plugin.name] = {
          enabled: plugin.enabled,
          config: plugin.config,
          type: plugin.type || "default",
        };

        return acc;
      }, {});

      if (_.isEmpty(this.selectedPersona.createdBy)) {
        this.selectedPersona.createdBy = this.userProfile?.email ?? "";
      }

      if (event === "update") {
        this.$store
          .dispatch("GENAI_UPDATE_PERSONA", { persona: this.selectedPersona })
          .then((result) => {
            if (result.success) {
              this.$message({
                type: "success",
                message: "Persona updated successfully",
              });
              this.unSavedChanges = this.unSavedChanges.filter(
                (id) => id !== this.selectedPersona.id
              );
              this.saving = false;
            } else {
              this.$message({
                type: "error",
                message: "Error updating persona",
              });
              this.saving = false;
            }
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "Error updating persona",
            });
            this.saving = false;
          });
      }
    },
    create() {
      // check if the persona name is not empty and not already exist
      const isValid =
        this.newPersonaName &&
        !this.tempPersonas.some((persona) => persona.name === this.newPersonaName);

      if (isValid) {
        const newPersona = {
          name: this.newPersonaName,
          prompt: DEFAULT_PERSONA_PROMPT,
          tags: {},
          beforeBotAction: {
            event: "",
            data: {},
            side_effects: Array,
          },
          afterBotAction: {
            event: "",
            data: {},
            side_effects: Array,
          },
          modelId: DEFAULT_PERSONA_MODEL,
          chatService: "OPENAI",
          temperature: 0.2,
          streaming_response: true,
          pluginConfig: {
            search_engine: {
              enabled: false,
              config: {
                name: "",
                whiteList: [],
              },
            },
            jira: {
              enabled: false,
              config: {
                apiKey: "",
                email: "",
                url: "",
              },
            },
          },
          chatHistoryLimit: 25,
          prompt_generation: {
            botName: "Bot",
            scope: "HealthCare",
            interactionMode: "",
            supportLanguage: ["English"],
            limitContentToPlugin: true,
            limitWordCount: 50,
            allowAskingClarifyingQuestions: true,
            allowCollectFeedback: true,
            preventCreative: true,
            preventCompetitors: true,
            preventOpinions: true,
            preventGenerate: true,
            safetyJailbreak: true,
            safetyViolence: true,
          },
          pii: false,
          createdBy: this.userProfile?.email ?? "",
        };

        this.$store
          .dispatch("GENAI_CREATE_PERSONA", { persona: newPersona })
          .then((result) => {
            if (result.success) {
              this.newPersonaName = "";
              this.showNewPersonaPopover = false;
              this.setPersonasCompareValue();
              this.setSelectPersona(this.personas.length - 1);
              this.$message({
                type: "success",
                message: "Persona created successfully",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "Error creating persona",
            });
          });
      } else {
        this.$notify({
          title: "Error",
          message: "Persona name is empty or already exist",
          type: "error",
        });
      }
    },
    openUploadDialog() {
      this.uploadDialogVisible = true;
    },
    isSelectedTagContains(categoryName) {
      return _.has(this.selectedTags, categoryName);
    },
    isPersonaTagsContain(categoryName) {
      return _.has(this.selectedPersona.tags, categoryName);
    },
    handleTagChange(itemName, value) {
      if (value) {
        this.$set(this.selectedTags, itemName, []);
        this.$set(this.selectedPersona.tags, itemName, []);
      } else {
        this.$delete(this.selectedTags, itemName);
        this.$delete(this.selectedPersona.tags, itemName);
      }
    },
    selectPersona() {
      const persona = this.sortedPersonaList[this.selectedPersonaIndex];
      if (!persona.tags) {
        persona.tags = {};
      }
      this.selectedTags = _.cloneDeep(persona.tags);

      if (!persona.beforeBotAction) {
        persona.beforeBotAction = {
          event: "",
          data: {},
          side_effects: Array,
        };
      }

      if (!persona.afterBotAction) {
        persona.afterBotAction = {
          event: "",
          data: {},
          side_effects: Array,
        };
      }

      if (!persona.prompt_generation) {
        persona.prompt_generation = {
          botName: "Bot",
          scope: persona.prompt,
          interactionMode: "",
          supportLanguage: ["English"],
          limitContentToPlugin: true,
          limitWordCount: 50,
          allowAskingClarifyingQuestions: true,
          allowCollectFeedback: true,
          preventCreative: true,
          preventCompetitors: true,
          preventOpinions: true,
          preventGenerate: true,
          safetyJailbreak: true,
          safetyViolence: true,
        };
      }

      if (persona.pluginConfig?.custom) {
        delete persona.pluginConfig.custom;
      }

      if (
        !persona.pluginConfig ||
        !persona.pluginConfig.jira ||
        !persona.pluginConfig.search_engine
      ) {
        {
          persona.pluginConfig = {
            search_engine: {
              enabled: false,
              config: {
                name: "",
                whiteList: [],
              },
            },
            jira: {
              enabled: false,
              config: {
                apiKey: "",
                email: "",
                url: "",
              },
            },
          };
        }
        this.tableFormatPlugins = this.initPlugins;
      } else {
        this.tableFormatPlugins = Object.keys(persona.pluginConfig).map((key) => {
          if (!persona.pluginConfig[key].config) {
            return {
              name: key,
              enabled: persona.pluginConfig[key].enabled,
              type: "default",
            };
          }
          return {
            name: key,
            enabled: persona.pluginConfig[key].enabled,
            config: persona.pluginConfig[key].config,
            type: persona.pluginConfig[key].type || "default",
          };
        });
      }

      if (!persona.chatHistoryLimit) {
        persona.chatHistoryLimit = 25;
      }

      this.selectedPersona = persona;
      this.originSelectedPersona = _.cloneDeep(persona);
      this.beforeBotAction = persona.beforeBotAction;
      this.afterBotAction = persona.afterBotAction;
    },
    setSelectPersona(index) {
      this.selectedPersonaIndex = index;
      this.selectPersona();
    },
    handleDeletePersona() {
      if (!this.isAbleToEditPersona(this.selectedPersona)) {
        this.$message({
          type: "error",
          message: "You don't have permission to delete this persona",
        });
        return;
      }

      this.$confirm("Are you sure you want to delete this persona?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const persona = this.tempPersonas[this.selectedPersonaIndex];
          const index = this.personas.findIndex((p) => p.id === persona.id);

          if (this.personas[index].name === DEFAULT_PERSONA_NAME || this.personas.length === 1) {
            this.$notify({
              title: "Error",
              message: "Cannot delete the default persona",
              type: "error",
            });
            return;
          }

          this.$store
            .dispatch("GENAI_DELETE_PERSONA", { id: this.personas[index].id })
            .then((result) => {
              if (result.success) {
                this.setPersonasCompareValue();
                this.setSelectPersona(0);

                this.$message({
                  type: "success",
                  message: "Persona deleted successfully",
                });
              }
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: "Error deleting persona",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    handleTagSelect(itemName, value) {
      this.$set(this.selectedTags, itemName, value);
    },
    showModal(plugin) {
      if (!plugin) {
        this.selectedModal = CustomizeModal;
        this.dialogVisible = true;
        this.selectedPlugin = {
          name: "custom",
          config: {
            name: "",
            fileUrl: "",
            authentication: {
              type: "none",
              token: "",
              username: "",
              password: "",
              xapikey: "",
            },
          },
        };
        return;
      }

      this.selectedPlugin = plugin;
      switch (plugin.name) {
        case "jira":
          this.selectedModal = JiraModal;
          break;
        case "search_engine":
          this.selectedModal = SearchEngineModal;
          break;
        default:
          this.selectedModal = CustomizeModal;
      }
      this.dialogVisible = true;
    },
    updateDialogVisible(value) {
      this.dialogVisible = value;
      if (!value) {
        this.selectedModal = null;
        this.selectedPlugin = null;
      }
    },
    updatePluginConfig(config) {
      this.selectedPlugin.config = config;
      this.dialogVisible = false;
      this.saveChanges({ event: "update" });
    },
    formatTableName(name) {
      return name.replace(/_/g, " ");
    },
    setPersonasCompareValue() {
      this.sortedPersonaList = _.cloneDeep(this.personas).sort((a, b) => {
        if (a.name === DEFAULT_PERSONA_NAME) {
          return -1;
        }
        if (b.name === DEFAULT_PERSONA_NAME) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
      this.tempPersonas = _.cloneDeep(this.sortedPersonaList);
    },
    addCustomPlugin(config) {
      const id = uuid();
      this.selectedPersona.pluginConfig[id] = {
        enabled: false,
        type: "custom",
        config,
      };
      this.tableFormatPlugins.push({
        name: id,
        enabled: false,
        type: "custom",
        config,
      });

      delete this.selectedPersona.pluginConfig.custom;
      this.tableFormatPlugins = this.tableFormatPlugins.filter(
        (plugin) => plugin.name !== "custom"
      );
    },
    handleDeletePlugin(plugin) {
      this.$confirm("Are you sure you want to delete this plugin?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const index = this.tableFormatPlugins.findIndex((p) => p.name === plugin.name);
          this.tableFormatPlugins.splice(index, 1);
          delete this.selectedPersona.pluginConfig[plugin.name];
          this.saveChanges({ event: "update" });
        })
        .catch(() => {});
    },
    isAbleToEditPersona(persona) {
      return (
        this.userProfile?.email === persona.createdBy ||
        this.allowEditRoles.some((role) => this.getUserAuthentication.roles.includes(role))
      );
    },
    openImportDialog() {
      this.$refs.importDialog.dialogVisible = true;
    },
    openExportDialog() {
      this.$refs.exportDialog.dialogVisible = true;
    },
    exportPersonas(selectedPersonaIds) {
      const selectedPersonas = this.personas.filter((p) => selectedPersonaIds.includes(p.id));
      const jsonString = JSON.stringify(selectedPersonas, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);

      // Create a download link
      const a = document.createElement("a");
      a.href = url;
      a.download = "export.json";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      this.$refs.exportDialog.dialogVisible = false;
      this.$refs.exportDialog.handleResetState();
    },
    importPersonas(selectedPersonas) {
      this.isLoadingImport = true;
      this.$store
        .dispatch("GENAI_IMPORT_PERSONAS", { personas: selectedPersonas })
        .then((data) => {
          this.isLoadingImport = false;
          this.$message({
            type: "success",
            message: "Personas imported successfully",
          });
          this.$refs.importDialog.dialogVisible = false;
          this.$refs.importDialog.handleResetState();
        })
        .catch(() => {
          this.isLoadingImport = false;
        });
    },
  },
  async created() {
    await this.$store.dispatch("GENAI_GET_PERSONAS");

    if (this.personas.length === 0) {
      const newPersona = {
        prompt: DEFAULT_PERSONA_PROMPT,
        name: DEFAULT_PERSONA_NAME,
        tags: {},
        streaming_response: true,
        ignoreChatOnAfterBot: true,
        beforeBotAction: {
          event: "",
          data: {},
          side_effects: Array,
        },
        afterBotAction: {
          event: "",
          data: {},
          side_effects: Array,
        },
        temperature: 0.2,
        modelId: DEFAULT_PERSONA_MODEL,
        chatService: "OPENAI",
        pluginConfig: {
          search_engine: {
            enabled: false,
            config: {
              name: "",
              whiteList: [],
            },
          },
          jira: {
            enabled: false,
            config: {
              apiKey: "",
              email: "",
              url: "",
              fileUrl: "",
            },
          },
        },
        chatHistoryLimit: 25,
        prompt_generation: {
          botName: "Bot",
          scope: "HealthCare",
          interactionMode: "",
          supportLanguage: ["English"],
          limitContentToPlugin: true,
          limitWordCount: 50,
          allowAskingClarifyingQuestions: true,
          allowCollectFeedback: true,
          preventCreative: true,
          preventCompetitors: true,
          preventOpinions: true,
          preventGenerate: true,
          safetyJailbreak: true,
          safetyViolence: true,
        },
        pii: false,
        createdBy: this.userProfile?.email ?? "",
      };
      this.$store.dispatch("GENAI_CREATE_PERSONA", { persona: newPersona });
    }
    this.setPersonasCompareValue();
    this.selectPersona();
  },
  watch: {
    selectedPersona: {
      handler: function (value) {
        if (!_.isEqual(value, this.originSelectedPersona)) {
          if (!this.unSavedChanges.includes(value.id)) {
            this.unSavedChanges.push(value.id);
          }
        }
      },
      deep: true,
    },
    selectedTags: {
      handler: function (value) {
        // remove empty tags from selectedTags and update the selectedPersona tags
        for (const key in value) {
          if (value[key].length !== 0) {
            this.sortedPersonaList[this.selectedPersonaIndex].tags[key] = value[key];
          }
        }
      },
      deep: true,
    },
    personas: {
      handler: function (value) {
        this.setPersonasCompareValue();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
#persona-header {
  margin-bottom: 10px;
  .save-button {
    width: 120px !important;
  }
}

#persona-body {
  display: flex;

  .persona-body-left {
    border: 1px solid #ebeef5;
    border-right: none;
    width: 15%;

    @media (max-width: 1024px) {
      margin-bottom: 20px;

      .persona-list {
        overflow-x: scroll;
        white-space: nowrap;

        .persona-item {
          width: fit-content;
          display: inline-block;
          margin: 5px;
          border: 1px solid #ebeef5;
        }
      }
    }
  }

  .persona-body-right {
    border: 1px solid #ebeef5;
    width: 85%;
    padding: 20px;
    display: flex;
    gap: 10px;

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  .persona-body-item-wrapper {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    box-sizing: border-box;
  }

  .persona-body-item {
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 20px;
    .persona-body-left,
    .persona-body-right {
      width: 100%;
    }
  }
}

.memory-bias-slider {
  width: 30%;
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
  }
}

.persona-list {
  padding: 0;
  margin: 0;
  .persona-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ebeef5;
    list-style: none;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    word-break: break-all;

    &:hover {
      background-color: #f5f7fa;
    }

    &.selected {
      background-color: #f5f7fa;
    }

    .persona-delete-button {
      color: red;
    }
  }
}

.wrap-responsive {
  @media (max-width: 1440px) {
    flex-direction: column;
    justify-content: start !important;
    gap: 0px !important;

    .persona-body-item {
      width: 100% !important;
    }
  }
}

.persona-table-wrapper {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

#persona-body {
  .el-collapse-item__arrow {
    margin-left: 0px !important;
  }
}

.persona-body-title {
  order: 2;
}

.persona-body-item .el-card {
  box-shadow: none !important;
}

.responsive-wrapper {
  max-width: 66%;
  width: 100%;
  height: fit-content;

  @media (max-width: 1024px) {
    max-width: 100%;
    height: fit-content;
  }
}
</style>
