<template>
  <div
    class="user-profile-container"
    style="max-height: 50vh; overflow-y: auto; padding: 0px 10px; overflow-x: hidden"
  >
    <p style="margin-left: 20px; font-size: 18px; font-weight: 700">USER PROFILE</p>
    <el-form label-position="top" ref="userDetails2" :model="formModel">
      <el-form-item label="Name" prop="name">
        <el-input v-model="formModel.name"></el-input>
      </el-form-item>
      <el-form-item label="Alias" prop="alias">
        <el-input v-model="formModel.alias"></el-input>
      </el-form-item>
      <el-form-item label="Mobile No" prop="mobileNo">
        <el-input v-model="formModel.mobileNo"></el-input>
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input v-model="formModel.email"></el-input>
      </el-form-item>
      <el-form-item label="Comments" prop="comments">
        <el-input
          type="textarea"
          v-model="formModel.comments"
          show-word-limit
          maxlength="2048"
          rows="6"
        ></el-input>
      </el-form-item>
      <div v-if="updated">
        <div v-for="(setting, key) in customFields" :key="key">
          <el-form-item 
            :label="setting.label || key"
            :rules="setting.rules"
            :prop="`customValues.${key}`"
          >
            <component
              v-model="formModel.customValues"
              :name="key"
              :setting="setting"
              :is="toComponent(setting)"
              :mode="smartFieldMode"
              @blur="validateField(key)"
            ></component>
          </el-form-item>
        </div>
      </div>
      <el-form-item>
        <el-button type="primary" @click="saveUserDetails">Save</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SmartFieldString from "@/components/ModuleEditor/SmartFields/String";
import SmartFieldSelect from "@/components/ModuleEditor/SmartFields/Select";
import SmartFieldMultiselect from "@/components/ModuleEditor/SmartFields/Multiselect";
import SmartFieldTextarea from "@/components/ModuleEditor/SmartFields/Textarea";

export default {
  name: "UserDetails",
  props: ["selectedChat", "value"],
  components: {
    SmartFieldString,
    SmartFieldSelect,
    SmartFieldMultiselect,
    SmartFieldTextarea,
  },
  data() {
    return {
      customValues: {},
      updated: false,
      smartFieldMode: "form",
      formModel: {
        ...this.value,
        customValues: {}
      }
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
      this.updated = false;
      const customUserDetailsFields = _.get(
        this,
        "$store.state.modules.handover.customUserProfile.fields",
        {}
      );
      const extended = Object.keys(customUserDetailsFields).reduce((acc, curr) => {
        return !newVal[curr]
          ? { ...acc, [curr]: customUserDetailsFields[curr]?.default || "" }
          : { ...acc, [curr]: newVal[curr] };
      }, {});
      this.customValues = extended;
        this.formModel = {
          ...newVal,
          customValues: extended
        };
      this.$nextTick(() => {
        this.updated = true;
      });
      }
    }
  },
  computed: {
    customFields() {
      const customUserDetailsEnabled = _.get(
        this,
        "$store.state.modules.handover.customUserProfile.enabled",
        false
      );
      if (!customUserDetailsEnabled) {
        return {};
      }
      const customUserDetailsFields = _.get(
        this,
        "$store.state.modules.handover.customUserProfile.fields",
        {}
      );
      return customUserDetailsFields;
    },
  },
  methods: {
    toComponent(setting) {
      const type = _.capitalize(setting.type);
      const componentName = `SmartField${type}`;
      const componentExist = this.$options.components[componentName];
      return componentExist ? componentName : "SmartFieldString";
    },
    validateField(field) {
      this.$refs.userDetails2.validateField(`customValues.${field}`);
    },
    saveUserDetails() {
      this.$refs.userDetails2.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "warning",
            message: "Please fix the validation errors before saving"
          });
          return;
        }
        const userDetails = {
          ...this.formModel,
          ...this.formModel.customValues
        };
        delete userDetails.customValues;
        
      this.$store
        .dispatch("UPDATE_USER_DETAILS", {
          chat: this.selectedChat,
            userDetails
        })
        .then((isSuccessful) => {
          if (isSuccessful) {
            this.$message({
              type: "success",
              message: "Successfully saved user details",
            });
          } else {
            this.$message({
              type: "error",
              message: "Failed to save user details",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "An unexpected error occurred while saving user details",
            });
          });
        });
    },
  },
};
</script>
