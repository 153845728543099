<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="500px"
    class="custom-dialog"
    :before-close="handleClose"
  >
    <template #title>
      <h3 class="dialog-title">Import Personas</h3>
    </template>

    <!-- File Upload Section -->
    <div v-if="personas.length === 0" class="upload-section">
      <p class="upload-text">Drop a file here or click to upload (JSON only)</p>
      <el-upload
        class="upload-demo"
        drag
        action="#"
        :file-list="fileList"
        accept=".json"
        :on-change="handleFileChange"
        :auto-upload="false"
        :limit="1"
      >
        <i class="el-icon-upload upload-icon"></i>
        <div class="el-upload__text">
          <em>Click to upload</em> or drag and drop a JSON file here
        </div>
      </el-upload>
    </div>

    <!-- Persona Selection Section -->
    <div v-else class="persona-selection">
      <p class="selection-text">Select personas to import</p>
      <el-checkbox-group v-model="selectedPersonas" class="checkbox-group">
        <el-checkbox
          v-for="(persona, index) in personas"
          :key="index"
          :label="persona.id"
          class="persona-checkbox"
        >
          {{ persona.name }}
        </el-checkbox>
      </el-checkbox-group>

      <!-- Re-Upload File -->
      <el-button size="small" type="primary" @click="handleResetState" style="margin-top: 20px">
        Re-Upload
      </el-button>
    </div>

    <!-- Footer Buttons -->
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="handleClose" type="danger" :loading="isLoading" :disabled="isLoading">
          Cancel
        </el-button>
        <el-button
          type="primary"
          @click="handleConfirm"
          :disabled="!selectedPersonas.length || isLoading"
          :loading="isLoading"
        >
          Confirm
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "ExportDialog",
  props: ["isLoading"],
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      personas: [],
      selectedPersonas: [],
    };
  },
  methods: {
    handleConfirm() {
      const selectedPersonas = this.personas.filter((p) => this.selectedPersonas.includes(p.id));
      this.$emit("import", selectedPersonas);
    },
    handleFileChange(file) {
      if (file.raw) {
        this.handleFileRead(file.raw);
      }
    },
    handleFileRead(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = JSON.parse(e.target.result);
          if (Array.isArray(data)) {
            this.personas = data;
          } else {
            this.$message.error("Invalid JSON format");
          }
        } catch (error) {
          this.$message.error("Error parsing JSON file");
        }
      };
      reader.readAsText(file);
    },
    handleResetState() {
      this.fileList = [];
      this.personas = [];
      this.selectedPersonas = [];
    },
    handleClose() {
      this.dialogVisible = false;
      this.handleResetState();
    },
  },
};
</script>

<style scoped>
.custom-dialog {
  border-radius: 8px;
}

.dialog-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.upload-section {
  text-align: center;
  padding: 20px;
}

.upload-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.upload-demo {
  width: 100%;
  border-radius: 8px;
}

.upload-icon {
  font-size: 24px;
  color: #409eff;
}

.persona-selection {
  padding: 10px 20px;
}

.selection-text {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.persona-checkbox {
  font-size: 14px;
  margin: 5px 0;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.dialog-footer .el-button {
  min-width: 100px;
}
</style>
