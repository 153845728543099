<template>
  <div>
    <!-- API PARAMS FIELD -->
    <el-form label-width="100px">
      <p>
        Purge chat data in the database between these dates according to the Handover -> Data
        Purging settings. If the setting is disabled, this will return 0 data to be updated. To
        avoid slowness, please choose a date range of no more than 2 days at a time.
      </p>

      <!-- DRY RUN -->
      <el-form-item label="Dry Run">
        <el-tooltip
          content="When enabled, the purge operation will run in simulation mode without making actual changes to the database"
          placement="top"
        >
          <el-switch v-model="dryRun" />
        </el-tooltip>
      </el-form-item>

      <!-- DATE RANGE-->
      <el-form-item label="Date range">
        <el-date-picker
          v-model="datePicked"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          format="dd/MM/yyyy"
          :default-time="['00:00:00', '23:59:59']"
          :disabled="loading"
        />
      </el-form-item>

      <!-- BUTTON -->
      <el-form-item>
        <el-button @click="onStart" type="success" plain :loading="loading" :disabled="loading"
          >Start</el-button
        >
      </el-form-item>
    </el-form>

    <!-- RESULT JSON -->
    <el-dialog title="Result" :visible.sync="dialogVisible" width="700px">
      <JSONEditor ref="jsonEditor" v-model="results" />
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import JSONEditor from "@/components/JSONEditor";
import { gql } from "@apollo/client/core";
import { graph } from "@/store/api";

export default {
  components: { JSONEditor },
  data() {
    return {
      dryRun: true,
      datePicked: [moment().subtract(1, "days").toDate(), moment().toDate()],
      results: {},
      fetching: false,
      loading: false,
      dialogVisible: false,
    };
  },
  methods: {
    async onStart() {
      try {
        const confirmed = await this.$confirm(
          `Are you sure? This might take a couple minutes`,
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
          }
        );

        if (confirmed) {
          this.loading = true;

          const payload = {
            startDate: moment(this.datePicked[0]).toISOString(),
            endDate: moment(this.datePicked[1]).toISOString(),
            dryRun: this.dryRun,
          };

          const response = await graph.mutate({
            mutation: gql`
              mutation ($data: JSON) {
                livechatAPI {
                  runLivechatDataPurge(data: $data)
                }
              }
            `,
            variables: {
              data: payload,
            },
            fetchPolicy: "no-cache",
          });

          this.results = _.get(response, "data.livechatAPI.runLivechatDataPurge", []);
          this.dialogVisible = true;

          this.$notify.success({
            title: "Success",
            message: "Finished",
            position: "bottom-right",
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify.error({
          title: "Error",
          message: _.toString(error),
          position: "bottom-right",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
