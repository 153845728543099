<template>
  <el-dialog :visible="visible" :before-close="handleClose" class="dialog-form-tag">
    <div class="filter-contacts-container">
      <el-form label-width="80px" label-position="left">
        <el-form-item label="Name">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="Color">
          <el-color-picker v-model="color" :predefine="predefineColors"></el-color-picker>
        </el-form-item>
        <el-form-item label="Filters">
          <div class="filter-contacts-container-filters">
            <div class="filter-contacts-container-filters-container">
              <div
                class="filter-contacts-container-filters-container-item"
                v-for="(value, key) in filterList"
                :key="key"
              >
                <el-select
                  size="small"
                  v-model="value.variable"
                  placeholder="Variable"
                  style="width: 500px"
                >
                  <el-option
                    v-for="(item, j) in variables"
                    :key="j"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  size="small"
                  v-model="value.comparison"
                  placeholder="Comparison"
                  style="width: 500px"
                >
                  <el-option
                    v-for="(item, j) in getComparisonOptionsByKey(key)"
                    :key="j"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <!-- channel select value -->
                <el-select
                  size="small"
                  v-model="value.value"
                  placeholder="Variable"
                  v-if="value.variable === 'channel'"
                >
                  <el-option
                    v-for="(item, j) in getOptionByVariable(value.variable)"
                    :key="j"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <!-- tag select value -->
                <el-select
                  size="small"
                  v-model="value.valueArray"
                  multiple
                  collapse-tags
                  placeholder="Variable"
                  v-else-if="value.variable === 'tags'"
                >
                  <el-option
                    v-for="(item, j) in getOptionByVariable(value.variable)"
                    :key="j"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <!-- stage select value -->
                <el-select
                  size="small"
                  v-model="value.value"
                  placeholder="Variable"
                  v-else-if="value.variable === 'stage'"
                >
                  <el-option
                    v-for="(item, j) in getOptionByVariable(value.variable)"
                    :key="j"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-input
                  placeholder="Value"
                  size="small"
                  v-model="value.value"
                  v-else-if="!getAttributeByName(value.variable).type"
                ></el-input>
                <div v-else-if="getAttributeByName(value.variable).type">
                  <el-input
                    placeholder="Value"
                    size="small"
                    v-model="value.value"
                    v-if="getAttributeByName(value.variable).type === ATTRIBUTE_TYPE.TEXT_BOX"
                  />
                  <el-select
                    size="small"
                    style="width: 100%"
                    v-model="value.value"
                    placeholder="Variable"
                    v-else-if="
                      getAttributeByName(value.variable).type === ATTRIBUTE_TYPE.DROPDOWN ||
                      getAttributeByName(value.variable).type === ATTRIBUTE_TYPE.CHECKBOX
                    "
                  >
                    <el-option
                      v-for="(item, j) in getAttributeByName(value.variable).options"
                      :key="j"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div
              style="width: 20px; margin-right: 5px"
              class="filter-contacts-container-filters-btn-add"
            >
              <img alt="" :src="AddIcon" @click="addNewComparison" v-show="!filterList[5]" />
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="filter-contacts-container-button">
        <el-button size="small" class="filter-contacts-container-button" @click="reset">
          Reset
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="filter-contacts-container-button"
          @click="submit"
        >
          {{ metric.id ? "Edit" : "Add" }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { OUT_REACH_TAG_TYPE } from "@/store/modules/outreach/types";
import { mapGetters } from "vuex";
import {
  getOutReachVariableExcludeField,
  OUT_REACH_FILTER_COMPARISON,
  optionChannelFilter,
  haveContainComparisonKey,
  getComparisonOptions,
} from "@/helperMethods/outreach";
import {
  ATTRIBUTE_TYPE,
  optionDropdownAttribute,
  optionCheckbox,
} from "@/helperMethods/outreach/attribute";
import AddIcon from "../../assets/add_icon.svg";
import _ from "lodash";

const defaultFilterList = [
  {
    variable: undefined,
    comparison: OUT_REACH_FILTER_COMPARISON.IS,
    value: "",
    valueArray: [],
  },
];

export default {
  props: ["visible", "metric"],
  data() {
    return {
      OUT_REACH_TAG_TYPE,
      id: this.metric?.id || null,
      name: this.metric?.name || "",
      color: this.metric?.color || "#0000FF",
      filterList: this.metric?.filterList || _.cloneDeep(defaultFilterList),
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
      ],
      // icon
      AddIcon,
      // import
      getComparisonOptions,
      // attribute helper
      ATTRIBUTE_TYPE,
    };
  },
  computed: {
    ...mapGetters([
      "contactsArray",
      "contactsTags",
      "contactsStages",
      "contactFilterBoard",
      "contactAttributes",
    ]),
    variables() {
      return getOutReachVariableExcludeField(undefined, this.contactAttributes);
    },
    contactAttributeMap() {
      return this.contactAttributes.reduce((res, item) => ({ ...res, [item.name]: item }), {});
    },
  },
  methods: {
    handleClose() {
      this.$emit("cancel");
    },
    getLengthName() {
      return this.name?.length || 0;
    },
    getAttributeByName(name) {
      const data = this.contactAttributeMap[name];
      if (!data) return { type: "", value: [] };
      const { type = ATTRIBUTE_TYPE.TEXT_BOX, value = [] } = data;
      let options = [];
      if (type === ATTRIBUTE_TYPE.DROPDOWN) {
        options = optionDropdownAttribute(value);
      }
      if (type === ATTRIBUTE_TYPE.CHECKBOX) {
        options = optionCheckbox();
      }
      return {
        type,
        options,
      };
    },
    getComparisonOptionsByKey(key) {
      const { variable } = this.filterList[key];
      if (!variable) return this.getComparisonOptions();
      const condition = haveContainComparisonKey.includes(variable);
      if (!condition) {
        this.filterList[key].comparison = OUT_REACH_FILTER_COMPARISON.IS;
      }
      return this.getComparisonOptions(condition);
    },
    addNewComparison() {
      this.filterList.push({ ...defaultFilterList[0] });
    },
    reset() {
      this.filterList = _.cloneDeep(defaultFilterList);
    },
    submit() {
      if (this.filterList[0].variable == null) {
        this.$alert("You need set a filter condition first.");
      } else {
        this.$emit("submit", {
          id: this.id,
          name: this.name,
          color: this.color,
          filterList: this.filterList,
          value: 0,
        });
      }
    },
    getOptionByVariable(variable) {
      if (variable === "tags") return this.contactsTags.data;
      if (variable === "stage") return this.contactsStages.data;
      if (variable === "channel") return optionChannelFilter();
      return "";
    },
  },
};
</script>

<style lang="scss">
$base-date-picker-color: #1d57d8;
$button-bg-hover: #e8eefb;
$button-border-hover: #bbcdf3;
.dialog-form-tag {
  .el-dialog__header {
    display: none !important;
  }
  .el-dialog__body {
    padding: 20px 32px 27px 23px !important;
  }
  &-container {
    &-header {
      > p {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
          Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;

        color: #000000;
      }
    }
    &-body {
      &-input {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        > p {
          font-weight: normal;
          margin-right: 6px !important;
          font-size: 14px;
          margin-bottom: 10px !important;
        }
        input {
          width: 100%;
        }
      }
      &-button {
        text-align: end;
        > button {
          border-color: $base-date-picker-color !important;
          background: none !important;
          color: $base-date-picker-color !important;
          &:hover {
            background: $button-bg-hover !important;
            border-color: $button-border-hover !important;
            border-color: $base-date-picker-color !important;
          }
        }
      }
    }
    .dialog-input {
      font-size: 14px;
      color: #909399;
      position: relative;
      &-length {
        position: absolute;
        right: 5px;
        bottom: 2px;
        display: flex;
        font-size: 12px;
        &-count {
          &.red {
            color: red !important;
          }
        }
      }
    }
  }
}
.filter-contacts {
  position: absolute;
  z-index: 999;
  width: 630px;
  height: 300px;
  background: white;
  top: 120%;
  left: 0;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  border-radius: 4px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &-container {
    &-filters {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      &-container {
        padding: 5px;
        width: 100%;
        overflow-y: scroll;
        height: 224px;
        &-item {
          display: flex;
          > div {
            padding: 5px;
            width: 100%;

            .el-input__inner {
              border-color: black !important;
              padding-left: 10px;
              &::placeholder {
                color: black;
                opacity: 1; /* Firefox */
              }
            }
          }
        }
      }

      &-btn-add {
        > img {
          margin: 15px 5px 0 0;
          height: fit-content;
          cursor: pointer;
        }
      }
    }
    &-button {
      text-align: end;
      padding-right: 20px;
      .reset {
        border-color: black !important;
        color: black !important;
        &:hover {
          background: rgba(50, 50, 50, 0.121) !important;
        }
      }
      .apply {
        border-color: #1d57d8 !important;
        color: #1d57d8 !important;
      }
      &:first-child {
        font-weight: 500;
      }
    }
  }
  .popper__arrow {
    display: none !important;
  }
  // &-click-outside {
  //   width: 100vw;
  //   height: 100vh;
  //   background: "blue";
  // }
}
</style>
