<template>
  <div>
    <div id="persona-header">
      <h1 class="main-title">Persona</h1>
      <el-button
        class="save-button"
        type="primary"
        :disabled="!isSavingEnabled"
        :loading="saving"
        @click="saveChanges"
        >Save</el-button
      >
    </div>
    <div id="persona-body">
      <div class="meta-prompt persona-body-item">
        <div class="meta-prompt-title persona-body-title">
          <span>Bot</span>
          <el-tooltip
            class="item"
            effect="dark"
            content="The prompt that defines the chat bot's persona."
            placement="right"
          >
            <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <el-select
          filterable
          v-model="selectedPersonaIndex"
          size="mini"
          style="width: 140px; margin-right: 5px"
        >
          <el-option
            :key="`persona-option-${index}`"
            v-for="(persona, index) in personas"
            :value="index"
            :label="persona.name"
            style="display: flex; justify-content: space-between; align-items: center"
          >
            <span style="float: left">{{ persona.name }}</span>
          </el-option>
        </el-select>
      </div>

      <div class="persona-body-item">
        <div class="meta-prompt-title persona-body-title">
          <span>Chat completion service</span>
        </div>
        <el-select
          filterable
          v-model="selectedChatService"
          size="mini"
          style="width: 140px; margin-right: 5px"
        >
          <el-option
            v-for="(service, index) in Object.keys(services)"
            :key="`chat-service-${index}-option`"
            :value="service"
            :label="service"
            style="display: flex; justify-content: space-between; align-items: center"
          >
            <span style="float: left">{{ service }}</span>
          </el-option>
        </el-select>
      </div>
      <div class="persona-body-item" v-if="services[selectedChatService] != null">
        <div class="meta-prompt-title persona-body-title">
          <span>AI Model</span>
        </div>
        <el-select
          filterable
          v-model="selectedModel"
          size="mini"
          style="width: 140px; margin-right: 5px"
        >
          <el-option
            :key="`openai-option-${index}`"
            v-for="(model, index) in services[selectedChatService]"
            :value="model"
            :label="model"
            style="display: flex; justify-content: space-between; align-items: center"
          >
            <span style="float: left">{{ model }}</span>
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: {
        OpenAI: ["gpt-4o", "gpt-4o-2024-08-06", "gpt-4o-mini", "gpt-4-turbo", "gpt-3.5-turbo"],
        Groq: [
          "llama-3.3-70b-versatile",
          "llama3-8b-8192",
          "llama3-70b-8192",
          "llama-3.1-8b-instant",
          "llama-3.1-70b-versatile",
          "llama-3.1-405b-reasoning",
          "mixtral-8x7b-32768",
          "gemma2-9b-it",
        ],
        Azure: ["gpt-4o"],
      },
      saving: false,
      selectedPersonaIndex: 0,
      selectedPersona: {},
      selectedModel: null,
      selectedChatService: null,
    };
  },
  computed: {
    personas() {
      return this.$store.state.genai.genai_personas;
    },
    isSavingEnabled() {
      return (
        !this.saving &&
        this.selectedPersonaIndex >= 0 &&
        this.selectedChatService != null &&
        (this.selectedModel != this.selectedPersona.modelId ||
          this.selectedChatService.toUpperCase() != this.selectedPersona.chatService)
      );
    },
  },
  methods: {
    saveChanges(event) {
      this.saving = true;
      this.selectedPersona.modelId = this.selectedModel;
      this.selectedPersona.chatService = this.selectedChatService;
      this.$store.dispatch("GENAI_UPDATE_PERSONA", { persona: this.selectedPersona }).then(() => {
        this.saving = false;
        this.$message({
          message: "Persona updated successfully",
          type: "success",
        });
      });
    },
    selectPersona() {
      const persona = this.personas[this.selectedPersonaIndex];
      this.selectedPersona = persona;
      if (!persona.chatService || persona.chatService.toUpperCase() === "OPENAI") {
        this.selectedChatService = "OpenAI";
      } else if (persona.chatService.toUpperCase() === "AZURE") {
        this.selectedChatService = "Azure";
      } else {
        this.selectedChatService = persona.chatService;
      }

      if (!this.services[this.selectedChatService].includes(persona.modelId)) {
        this.selectedModel = this.getDefaultModel();
      } else {
        this.selectedModel = persona.modelId;
      }
    },
    getDefaultModel() {
      const models = this.services[this.selectedChatService];

      if (models) return models[0];
      return null;
    },
  },
  mounted() {
    this.selectedPersonaIndex = 0;
    this.selectPersona();
  },
  watch: {
    selectedPersonaIndex: function (value) {
      // If "pageData" ever changes, then we will console log its new value.
      this.selectPersona();
    },
    selectedChatService: function (value) {
      if (value !== this.selectedPersona.chatService) {
        this.selectedModel = this.getDefaultModel();
      }
    },
  },
  async created() {
    await this.$store.dispatch("GENAI_GET_PERSONAS");
  },
};
</script>

<style lang="scss">
#persona-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .save-button {
    width: 240px;
  }
}

#persona-body {
  .persona-body-title {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 400;
  }
}

.memory-bias-slider {
  width: 30%;
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
  }
}
</style>
