import { RootState } from "@/store/types";
import { SmartClassifierState } from "./types";
import { ActionTree } from "vuex";
import { rest } from "@/store/api";

const actions: ActionTree<SmartClassifierState, RootState> = {
  SMART_CLASSIFIER_FETCH_INTENTS: ({ commit }, brain) => {
    commit("SMART_CLASSIFIER_SET_FETCHING", true);
    return rest("post", "get_smart_classifier_intents", { brain })
      .then((response) => {
        commit("SMART_CLASSIFIER_SET_INTENTS", response.dataset);
        commit("SMART_CLASSIFIER_SET_FALLBACK", response.fallback);
        commit("SMART_CLASSIFIER_SET_FETCHING", false);
      })
      .catch(() => {
        const dataset = [];
        const fallback = "fallback_too_complex";
        commit("SMART_CLASSIFIER_SET_INTENTS", dataset);
        commit("SMART_CLASSIFIER_SET_FALLBACK", fallback);
        commit("SMART_CLASSIFIER_SET_FETCHING", false);
      });
  },
  SMART_CLASSIFIER_SAVE_INTENT: async ({ state, commit }, { brain }) => {
    const intents = state.intents;
    // remove empty intent
    const filteredIntents = intents.filter((intent) => intent.intentName !== "");
    // remove duplicate intent
    const uniqueIntents = filteredIntents.filter(
      (intent, index) =>
        filteredIntents.findIndex((i) => i.intentName === intent.intentName) === index
    );

    const result = await rest("post", "save_smart_classifier_intent", {
      brain,
      intents: { dataset: uniqueIntents, fallback: state.fallback },
    });
    if (result) {
      commit("SMART_CLASSIFIER_SET_INTENTS", result.dataset);
      return result;
    }
    return false;
  },
  SET_SMART_CLASSIFIER_FALLBACK: ({ commit }, payload) => {
    commit("SMART_CLASSIFIER_SET_FALLBACK", payload);
  },
  SMART_CLASSIFIER_DELETE_INTENT: async ({ state, commit }, intentName) => {
    commit("SMART_CLASSIFIER_DELETE_INTENT", intentName);
  },
};

export default actions;
