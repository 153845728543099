<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="Export Personas"
    width="400px"
    :destroy-on-close="true"
  >
    <div class="export-dialog">
      <!-- Search bar for filtering personas -->
      <el-input v-model="searchQuery" placeholder="Search personas..." clearable />

      <!-- Multiple select checkboxes persona -->
      <el-scrollbar max-height="300px">
        <p class="selection-text">Select personas to export</p>
        <el-checkbox-group v-model="selectedPersonas">
          <div v-for="persona in filteredPersonas" :key="persona.id">
            <el-checkbox :label="persona.id">
              {{ persona.name }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </el-scrollbar>

      <!-- Footer actions -->
      <div class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="handleConfirm" :disabled="!selectedPersonas.length">
          Confirm
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ExportDialog",
  props: ["personas"],
  data() {
    return {
      dialogVisible: false,
      selectedPersonas: [],
      searchQuery: "",
    };
  },
  computed: {
    filteredPersonas() {
      return this.personas.filter((persona) =>
        persona.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    handleConfirm() {
      this.$emit("export", this.selectedPersonas);
    },
    handleResetState() {
      this.selectedPersonas = [];
      this.searchQuery = "";
    },
    handleClose() {
      this.dialogVisible = false;
      this.handleResetState();
    },
  },
};
</script>

<style scoped>
.export-dialog {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.persona-card {
  border: 1px solid #ebeef5;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: none;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}
</style>
